import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c(VLayout,{attrs:{"wrap":""}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"top-bar-bg"},[_c('div',{staticClass:"top-bar-container"},[_c('div',{staticClass:"headline"},[_vm._v("Profile")]),_vm._v(" "),_c(VTabs,{attrs:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,{key:"profile"},[_vm._v("\n                        Summary\n                    ")]),_vm._v(" "),_c(VTab,{key:"edit"},[_vm._v("\n                        Edit\n                    ")]),_vm._v(" "),_c(VTab,{key:"password"},[_vm._v("\n                        Change Password\n                    ")]),_vm._v(" "),_c(VTab,{key:"notifications"},[_vm._v("\n                        Settings\n                    ")])],1)],1),_vm._v(" "),_c(VDivider)],1),_vm._v(" "),_c(VTabsItems,{staticClass:"full-width",staticStyle:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabItem,{key:"profile"},[_c('my-profile',{ref:"myProfile",attrs:{"profile":_vm.profile.profile},on:{"tab-navigation":_vm.tabNavigation}})],1),_vm._v(" "),_c(VTabItem,{key:"edit"},[_c('profile-form',{attrs:{"profile":_vm.profile.profile},on:{"tab-navigation":_vm.tabNavigation,"profile-updated":_vm.profileUpdated}})],1),_vm._v(" "),_c(VTabItem,{key:"password"},[_c('change-password',{on:{"tab-navigation":_vm.tabNavigation}})],1),_vm._v(" "),_c(VTabItem,{key:"notifications"},[_c('manage-notifications',{attrs:{"profile":_vm.profile.profile,"user-settings":_vm.profile.userSettings,"timezone-array":_vm.initialProfile.timezoneArray},on:{"tab-navigation":_vm.tabNavigation}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }