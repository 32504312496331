<template>
    <v-container style="max-width: 800px;">
        <v-row>
            <v-col class="px-8" sm="6">
                <p class="headline">Current Payment Method</p>
                <p class="subtitle">
                    You cannot remove your billing method. Please use the form
                    if your details are no longer valid.
                </p>
                <v-container v-if="billingInfo.hasPlan">
                    <p style="color:yellowgreen">
                        Note: {{ billingInfo.smartdata_comment }}
                    </p>
                    <credit-card :card="billingInfo.card" />
                </v-container>
                <v-container v-else>
                    <p style="color:red">
                        Note: This Billing Account is yet to subscribe to it
                        first plan.
                    </p>
                </v-container>
            </v-col>

            <v-col class="px-8" sm="6">
                <v-row>
                    <p class="headline">Update Payment Method</p>
                    <stripe-form
                        :billing-info="billingInfo"
                        :billing-plans="billingPlans"
                        :is-update-form="true"
                    />
                </v-row>
            </v-col>
        </v-row>

        <v-row>
            <v-col class="px-8" sm="6">
                <p class="headline">Current Plan</p>
                <div>Plan Name: {{ billingInfo.plan.name }}</div>
                <div>
                    Plan Cost:
                    <span v-if="billingInfo.plan.currency === 'EUR'">€</span
                    ><span v-else>$</span>{{ billingInfo.plan.cost }}
                    {{ billingInfo.plan.currency }}
                </div>
                <div>
                    Plan Frequency: Every {{ billingInfo.plan.frequency }}
                    {{ month_plural }}
                </div>
                <br />
                <p class="subtitle">
                    To cancel this plan, please <contact-support />.
                </p>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import ContactSupport from 'components/SmallComponents/ContactSupport/ContactSupport.vue';
import CreditCard from 'components/Stripe/CreditCard/CreditCard.vue';
import StripeForm from 'components/Stripe/StripeForm/StripeForm.vue';

export default {
    name: 'PlanAndBilling',
    components: { CreditCard, ContactSupport, StripeForm },
    props: {
        billingAccountId: {
            type: String,
            default: function() {
                return null;
            }
        },
        billingPlans: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data: function() {
        return {
            dialog: false,
            stripe: null,
            elements: null,
            cardElement: null,
            billingInfo: null
        };
    },
    computed: {
        month_plural() {
            if (this.billingInfo.plan.frequency > 1) {
                return 'months';
            } else {
                return 'month';
            }
        }
    },
    mounted() {
        // eslint-disable-next-line no-undef
        this.stripe = window.Stripe(window.NDSD_STRIPE_PK);
        this.elements = this.stripe.elements();

        const style = {
            base: {
                color: '#32325d',
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                '::placeholder': {
                    color: '#aab7c4'
                }
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a'
            }
        };

        this.cardElement = this.elements.create('card', { style: style });
        let that = this;
        setTimeout(() => {
            that.cardElement.mount('#card-element');
        }, 500);

        this.getAccountInfo();
    },
    methods: {
        getAccountInfo() {
            axios
                .get(
                    `/api/billing/${this.billingAccountId}/get_plan_and_billing_info/`
                )
                .then(response => response.data)
                .then(data => {
                    this.billingInfo = data;
                })
                .catch(() => {
                    this.$refs.snackbar.show(
                        'The billing account information could not be retrieved.'
                    );
                });
        },
        savePaymentMethod(paymentMethodObject) {
            axios
                .post(`/api/billing/payment/save`, paymentMethodObject)
                .then(response => response.data)
                .then(data => {
                    if (data.success) {
                        window.location.pathname = '/';
                    } else {
                        throw 'Unknown error when saving payment details.';
                    }
                })
                .catch(() => {
                    this.errorMessage =
                        'There was an unknown error while saving your payment method.';
                });
        },
        getPaymentMethod() {
            let that = this;
            this.stripe
                .createPaymentMethod({
                    type: 'card',
                    card: this.cardElement,
                    billing_details: {
                        email: this.billingInfo.email,
                        name: this.billingInfo.contact_name
                    }
                })
                .then(result => {
                    // Handle result.error or result.paymentMethod
                    result = result.paymentMethod;

                    let paymentMethod = {
                        billingAccountId: that.billingAccountId,
                        id: result.id,
                        card: {
                            last4: result.card.last4,
                            exp:
                                result.card.exp_month +
                                '/' +
                                result.card.exp_year,
                            country: result.card.country,
                            brand: result.card.brand
                        }
                    };

                    that.savePaymentMethod(paymentMethod);
                });
        }
    }
};
</script>

<style scoped>
.MyCardElement {
    height: 40px;
    padding: 10px 12px;
    width: 100%;
    color: #32325d;
    background-color: white;
    border: 1px solid transparent;
    border-radius: 4px;

    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
}

.MyCardElement--focus {
    box-shadow: 0 1px 3px 0 #cfd7df;
}

.MyCardElement--invalid {
    border-color: #fa755a;
}

.MyCardElement--webkit-autofill {
    background-color: #fefde5 !important;
}

.payment-row {
    max-width: 400px;
}
</style>
