<template>
    <v-flex>
        <div>
            <div class="top-bar-container">
                <v-breadcrumbs :items="breadCrumbs" divider="/"></v-breadcrumbs>

                <v-tabs v-model="currentTab" background-color="transparent">
                    <v-tab key="contactDetails">Contact Details</v-tab>
                    <v-tab key="planAndBilling">Plan & Billing</v-tab>
                    <v-tab key="accountAdministrators">Administrators </v-tab>
                </v-tabs>
            </div>

            <v-divider></v-divider>
        </div>

        <v-tabs-items
            v-model="currentTab"
            class="full-width"
            style="background-color: transparent;"
        >
            <v-tab-item key="contactDetails">
                <billing-account-form
                    :billing-account-id="billingAccountId"
                ></billing-account-form>
            </v-tab-item>

            <v-tab-item key="planAndBilling">
                <payment-update-form
                    :billing-account-id="billingAccountId"
                    :billing-plans="billingPlans"
                ></payment-update-form>
            </v-tab-item>

            <v-tab-item key="accountAdministrators">
                <billing-account-administrators
                    :billing-account-id="billingAccountId"
                ></billing-account-administrators>
            </v-tab-item>
        </v-tabs-items>
    </v-flex>
</template>

<script>
import BillingAccountAdministrators from 'components/BillingAccounts/BillingAccountAdministrators/BillingAccountAdministrators.vue';
import BillingAccountForm from 'components/BillingAccounts/BillingAccountForm/BillingAccountForm.vue';
import PaymentUpdateForm from 'components/BillingAccounts/PlanAndBilling/PlanAndBilling.vue';

export default {
    name: 'ManageBillingAccount',
    components: {
        PaymentUpdateForm,
        BillingAccountAdministrators,
        BillingAccountForm
    },
    props: {
        billingAccountId: {
            type: String,
            default: null
        },
        billingPlans: {
            type: Array,
            default: function() {
                return [];
            }
        }
    },
    data: function() {
        return {
            currentTab: 0,
            tabTitle: 'Manage Billing Account',
            breadCrumbs: [
                {
                    text: 'Billing Accounts',
                    disabled: false,
                    href: `${window.location.origin}/billing/`
                },
                {
                    text: 'Manage Billing Account',
                    disabled: true,
                    href: '#'
                }
            ]
        };
    },
    methods: {
        tabNavigated(value) {
            if (value === 'edit') {
                this.currentTab = 1;
                this.tabTitle = 'Contact Details';
            } else if (value === 'admins') {
                this.currentTab = 2;
                this.tabTitle = 'Administrators';
            }
        }
    }
};
</script>

<style scoped></style>
