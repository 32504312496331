<template>
    <v-dialog v-model="show" width="600" max-height="350">
        <v-card
            width="600"
            max-height="350"
            class="mx-auto text-center align-center justify-center pa-3"
        >
            <v-card-text>
                <p class="pt-2" style="font-size: 1.5rem;">
                    Two Factor Authentication
                </p>
                <p
                    v-if="!error && codeSent"
                    class="pt-2"
                    style="font-size: 1.0rem;"
                >
                    A security code has been to sent your phone. Please type it
                    in below to continue.
                </p>
                <template v-if="mainLoader">
                    <v-progress-circular indeterminate color="primary" />
                </template>
                <v-form ref="form">
                    <v-container grid-list-xl>
                        <v-list color="transparent" subheader two-line flat>
                            <v-list-item v-if="verificationSuccess">
                                <v-alert outlined type="success" text>
                                    You have been successfully authenticated.
                                </v-alert>
                            </v-list-item>

                            <v-list-item v-if="error">
                                <v-alert outlined type="error" text>
                                    {{ errorText }} Click below to send the code
                                    again.
                                </v-alert>
                            </v-list-item>

                            <v-list-item v-if="codeSent">
                                <v-text-field
                                    v-model="code"
                                    label="Security code"
                                    :rules="rules.securityCode"
                                    :counter="7"
                                    prepend-icon="$vuetify.icons.lock"
                                >
                                </v-text-field>
                            </v-list-item>
                        </v-list>
                        <v-layout justify-center>
                            <v-btn
                                v-if="codeSent"
                                large
                                color="primary"
                                :loading="loader"
                                @click="submit"
                            >
                                Submit
                            </v-btn>

                            <v-btn
                                v-if="error"
                                large
                                color="primary"
                                :loading="loader"
                                @click="sendCode"
                            >
                                Send
                            </v-btn>
                        </v-layout>
                    </v-container>
                </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
    name: 'TwoFactorAuthentication',
    data: () => {
        return {
            authyId: '',
            code: null,
            loader: false,
            mainLoader: false,
            rules: {
                securityCode: [
                    value => {
                        const pattern = /^\d*$/;
                        return (
                            pattern.test(value) ||
                            'Security codes are numbers only.'
                        );
                    },
                    value => !!value || 'Required field',
                    value =>
                        value.length === 7 ||
                        'Security code must be 7 characters.',
                ],
            },
            verificationSuccess: false,
            error: false,
            errorText: null,
            show: false,
            codeSent: false,
        };
    },
    methods: {
        init(authyId, twoFactorFailed = false) {
            this.authyId = authyId;
            this.codeSent = false;
            if (twoFactorFailed) {
                this.error = true;
                this.errorText = 'The security code was incorrect.';
            } else {
                this.sendCode();
            }

            this.show = true;
            this.code = '';
        },
        close() {
            this.show = false;
        },
        submit() {
            if (!this.$refs.form.validate()) return;
            this.$emit('token-ready', this.code);
        },
        sendCode() {
            this.error = false;
            this.loader = true;
            this.mainLoader = true;
            axios
                .post(
                    '/api/accounts/authy_send_code',
                    JSON.stringify({
                        authyId: this.authyId,
                    })
                )
                .then(response => {
                    this.mainLoader = false;
                    this.loader = false;
                    if (response.status === 200) {
                        this.codeSent = true;
                    } else {
                        this.error = response.status !== 200;
                    }
                })
                .catch(error => {
                    this.mainLoader = false;
                    this.loader = false;
                    this.error = true;
                    this.errorText =
                        'There was an error sending the security code. If the error persists, contact Smartdata support.';
                    throw error;
                });
        },
    },
};
</script>

<style scoped />
