import ManageBillingAccount from 'components/BillingAccounts/ManageBillingAccount/ManageBillingAccount.vue';
import MyBillingAccounts from 'components/BillingAccounts/MyBillingAccounts/MyBillingAccounts.vue';
import LoginCard from 'components/Profile/LoginCard/LoginCard.vue';
import PasswordResetConfirm from 'components/Profile/PasswordReset/PasswordResetConfirm/PasswordResetConfirm.vue';
import PasswordResetRequest from 'components/Profile/PasswordReset/PasswordResetRequest/PasswordResetRequest.vue';
import TwoFactorAuthentication from 'components/Profile/PhoneNumber/LoginPhoneVerification.vue';
import ProfilePage from 'components/Profile/ProfilePage/ProfilePage.vue';
import Register from 'components/Profile/Register/Register.vue';
import SupportPage from 'components/SupportPage/SupportPage.vue';
import mainMixin from 'js/mixins/mainMixin';
import Vue from 'vue';

// eslint-disable-next-line no-unused-vars, unicorn/prevent-abbreviations
Vue.config.warnHandler = (msg, vm, trace) => {
    // `trace` is the component hierarchy trace
    if (msg.includes('The .native modifier for v-on')) {
        msg = null;
        vm = null;
        trace = null;
    }
};

// eslint-disable-next-line no-new
new Vue({
    components: {
        LoginCard,
        SupportPage,
        MyBillingAccounts,
        ManageBillingAccount,
        PasswordResetConfirm,
        PasswordResetRequest,
        Register,
        ProfilePage,
        TwoFactorAuthentication,
    },
    mixins: [mainMixin],
    data: {
        pageName: 'Generic Page',
    },
    computed: {},
    watch: {},
    mounted() {},
    methods: {},
});
