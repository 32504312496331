import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"color":"py-0"}},[_c(VRow,{staticStyle:{"margin":"0 auto","max-width":"1000px"}},[_c(VSpacer),_vm._v(" "),_c(VAutocomplete,{ref:"autocomplete",staticStyle:{"max-width":"400px"},attrs:{"id":"email","type":"email","loading":_vm.loading,"items":_vm.results,"search-input":_vm.search,"placeholder":"Add user by email address","autocomplete":"off"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(!_vm.search || (_vm.search && _vm.search.length <= 3))?_c(VListItem,[_c(VListItemTitle,[_c('span',{domProps:{"textContent":_vm._s(_vm.search ? 'Keep' : 'Start')}}),_vm._v("\n                        typing to search for an email address\n                    ")])],1):_c(VListItem,[_c(VListItemTitle,[_vm._v("\n                        No matching email addresses.\n                    ")])],1)]},proxy:true}]),model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_vm._v(" "),_c(VBtn,{staticClass:"ml-3 mt-3 mr-4",attrs:{"color":"primary","disabled":!_vm.email,"loading":_vm.loadingButton},on:{"click":_vm.addUser}},[_c(VIcon,{staticStyle:{"font-size":"12pt"},domProps:{"textContent":_vm._s('$vuetify.icons.register')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }