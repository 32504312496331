<template>
    <a href="#" @click="openIntercom">{{ text }}</a>
</template>

<script>
export default {
    name: 'ContactSupport',
    props: {
        text: {
            type: String,
            default: function() {
                return 'contact support';
            }
        }
    },
    methods: {
        openIntercom() {
            window.Intercom('show');
        }
    }
};
</script>
