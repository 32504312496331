<template>
    <v-flex>
        <div
            v-if="$vuetify.breakpoint.mdAndUp"
            :class="dark ? 'top-bar-bg-dark' : 'top-bar-bg'"
        >
            <div class="top-bar-container">
                <div class="headline">Request Password Reset</div>
            </div>
            <v-divider></v-divider>
        </div>

        <v-card flat class="tab-card" color="transparent" justify-center>
            <v-container>
                <v-list justify-center color="transparent">
                    <v-list-item v-if="response">
                        <v-alert
                            dense
                            outlined
                            :type="requestSuccessful ? 'success' : 'error'"
                            text
                        >
                            {{ message }}
                        </v-alert>
                    </v-list-item>

                    <v-list-item v-if="!response">
                        <p>
                            Enter your email and a password reset link will be
                            sent to your email address.
                        </p>
                    </v-list-item>
                </v-list>
                <v-layout
                    v-if="!response || !requestSuccessful"
                    row
                    justify-center
                    :class="{ 'pa-8': $vuetify.breakpoint.mdAndUp }"
                >
                    <v-form class="full-width">
                        <v-container>
                            <v-list color="transparent" subheader two-line flat>
                                <v-list-item>
                                    <v-text-field
                                        v-model="email"
                                        label="Email"
                                        required
                                        outlined
                                        prepend-icon="$vuetify.icons.email"
                                        class="pb-4"
                                    >
                                    </v-text-field>
                                </v-list-item>
                            </v-list>

                            <v-layout class="mt-5" justify-center>
                                <v-btn
                                    large
                                    color="primary"
                                    @click="requestPasswordReset"
                                    >Request Password Reset<v-icon
                                        right
                                        v-text="'$vuetify.icons.next'"
                                    ></v-icon
                                ></v-btn>
                            </v-layout>
                        </v-container>
                    </v-form>
                </v-layout>
            </v-container>
        </v-card>
    </v-flex>
</template>

<script>
import axios from 'axios';
import { trackProfileEvents } from 'js/eventsTracking';

export default {
    name: 'PasswordResetForm',
    props: {
        userEmail: {
            type: String,
            default: null,
        },
        dark: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            email: null,
            requestSuccessful: false,
            response: false,
            message: null,
        };
    },
    mounted() {
        if (this.userEmail) {
            this.email = this.userEmail;
        }
    },
    methods: {
        requestPasswordReset() {
            this.response = false;
            axios
                .post(
                    `/accounts/password/reset/request/`,
                    JSON.stringify({ email: this.email })
                )
                .then(response => response.data)
                .then(data => {
                    trackProfileEvents.requestedPasswordReset();
                    this.response = true;
                    this.requestSuccessful = data.success;
                    this.message = data.message;
                });
        },
    },
};
</script>
