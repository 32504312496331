<template>
    <v-container>
        <v-layout
            row
            justify-center
            :class="{ 'pa-8': $vuetify.breakpoint.mdAndUp }"
        >
            <v-form class="full-width" @input="formInput">
                <v-container>
                    <v-list color="transparent" subheader two-line flat>
                        <v-list-item>
                            <p>
                                Your password reset is nearly finished. Please
                                enter your new password below.
                            </p>
                        </v-list-item>

                        <v-list-item v-if="response">
                            <v-alert
                                dense
                                outlined
                                :type="resetSuccessful ? 'success' : 'error'"
                                text
                            >
                                {{ message }}
                            </v-alert>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.newPassword1"
                                :disabled="disableForm"
                                :hide-details="true"
                                label="New Password"
                                prepend-inner-icon="$vuetify.icons.shield"
                                filled
                                :rules="[requiredRule]"
                                :append-icon="
                                    showPasswords
                                        ? '$vuetify.icons.eye'
                                        : '$vuetify.icons.eyeSlash'
                                "
                                :type="showPasswords ? 'text' : 'password'"
                                @click:append="showPasswords = !showPasswords"
                            ></v-text-field>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.newPassword2"
                                :disabled="disableForm"
                                :hide-details="true"
                                label="Confirm New Password"
                                prepend-inner-icon="$vuetify.icons.shield"
                                filled
                                :rules="[requiredRule]"
                                :append-icon="
                                    showPasswords
                                        ? '$vuetify.icons.eye'
                                        : '$vuetify.icons.eyeSlash'
                                "
                                :type="showPasswords ? 'text' : 'password'"
                                @click:append="showPasswords = !showPasswords"
                            ></v-text-field>
                        </v-list-item>
                    </v-list>

                    <v-layout class="mt-5" justify-center>
                        <v-btn large color="primary" @click="resetPassword"
                            >Reset Password<v-icon
                                right
                                v-text="'$vuetify.icons.next'"
                            ></v-icon
                        ></v-btn>
                    </v-layout>
                </v-container>
            </v-form>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import { trackProfileEvents } from 'js/eventsTracking';

export default {
    name: 'PasswordResetConfirm',
    data: function() {
        return {
            form: {
                newPassword1: null,
                newPassword2: null,
            },
            requiredRule: value => !!value || 'Required field',
            response: false,
            message: null,
            showPasswords: false,
            resetSuccessful: null,
            btnDisable: true,
        };
    },
    methods: {
        formInput() {
            this.btnDisable = false;
        },
        resetPassword() {
            this.response = false;
            axios
                .post(
                    `${window.location}`,
                    JSON.stringify({
                        new_password1: this.form.newPassword1,
                        new_password2: this.form.newPassword2,
                    })
                )
                .then(response => response.data)
                .then(data => {
                    trackProfileEvents.completedPasswordReset();
                    this.response = true;
                    this.resetSuccessful = data.success;
                    this.message = data.message;
                    if (data.success) {
                        window.location.replace(
                            `${window.location.origin}/accounts/login`
                        );
                    }
                });
        },
    },
};
</script>

<style scoped></style>
