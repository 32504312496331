<template>
    <v-card
        max-width="450"
        class="mx-auto text-center align-center justify-center"
    >
        <v-card-text>
            <template>
                <p class="pt-2" style="font-size: 1.5rem;">
                    Register
                </p>
                <v-form ref="form" @submit.prevent="submitButton">
                    <v-container grid-list-xl>
                        <v-list color="transparent" subheader two-line flat>
                            <v-list-item v-if="registrationSuccess">
                                <v-alert outlined type="success" text>
                                    Your account has been successfully
                                    registered. You must verify your email
                                    before you can login. Please check your
                                    email.
                                </v-alert>
                            </v-list-item>

                            <template v-if="!registrationSuccess">
                                <v-list-item v-if="errors.compromisedPassword">
                                    <v-alert outlined type="error" text>
                                        Your password has been involved in a
                                        major security breach and cannot be
                                        used.
                                    </v-alert>
                                    <v-btn
                                        color="primary"
                                        text
                                        @click="HIBPDialog = true"
                                        >More Info
                                    </v-btn>
                                </v-list-item>

                                <v-list-item>
                                    <v-text-field
                                        v-model="user.email"
                                        autofocus
                                        :rules="[rules.email]"
                                        :counter="254"
                                        label="Email"
                                        prepend-icon="$vuetify.icons.email"
                                        :error-messages="errors.email"
                                        :disabled="emailDisabled"
                                        @change="errors.email = ''"
                                    />
                                </v-list-item>

                                <v-list-item>
                                    <v-text-field
                                        v-model="user.firstName"
                                        :rules="[rules.required]"
                                        :counter="25"
                                        label="First Name"
                                        prepend-icon="$vuetify.icons.profile"
                                        :error-messages="errors.first_name"
                                        @change="errors.first_name = ''"
                                    />
                                </v-list-item>

                                <v-list-item>
                                    <v-text-field
                                        v-model="user.lastName"
                                        :rules="[rules.required]"
                                        :counter="25"
                                        label="Last Name"
                                        prepend-icon="$vuetify.icons.profile"
                                        :error-messages="errors.last_name"
                                        @change="errors.last_name = ''"
                                    />
                                </v-list-item>

                                <v-list-item>
                                    <v-autocomplete
                                        v-model="user.countryCode"
                                        label="Country code"
                                        required
                                        :rules="[rules.required]"
                                        :items="countryCodeArray"
                                        item-text="country"
                                        item-value="code"
                                        prepend-icon="$vuetify.icons.phone"
                                        autocomplete="off"
                                    />
                                </v-list-item>

                                <v-list-item>
                                    <v-text-field
                                        v-model="user.phoneNumber"
                                        :rules="rules.phoneNumber"
                                        :counter="10"
                                        label="Phone number"
                                        prepend-icon="$vuetify.icons.mobile"
                                    />
                                </v-list-item>

                                <v-list-item>
                                    <v-text-field
                                        v-model="user.password1"
                                        :rules="[rules.required]"
                                        label="Password"
                                        prepend-icon="$vuetify.icons.lock"
                                        :append-icon="
                                            showPassword
                                                ? '$vuetify.icons.eye'
                                                : '$vuetify.icons.eyeSlash'
                                        "
                                        :error-messages="errors.password1"
                                        hint="Your password can't be too similar to your other information, less than 8 characters, a commonly used password or entirely numeric. You agree to let us check if you password has been compromised."
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                    />
                                </v-list-item>

                                <v-list-item>
                                    <v-text-field
                                        v-model="user.password2"
                                        :rules="[rules.required]"
                                        label="Confirm Password"
                                        :type="
                                            showPassword ? 'text' : 'password'
                                        "
                                        :append-icon="
                                            showPassword
                                                ? '$vuetify.icons.eye'
                                                : '$vuetify.icons.eyeSlash'
                                        "
                                        :error-messages="errors.password2"
                                        prepend-icon="$vuetify.icons.lock"
                                        @click:append="
                                            showPassword = !showPassword
                                        "
                                    />
                                </v-list-item>

                                <v-list-item>
                                    <v-checkbox
                                        v-model="terms_checkbox"
                                        :rules="[rules.mustBeTrue]"
                                        :error="errors.terms_checkbox"
                                    >
                                        <template #label>
                                            <div>
                                                I have read and agree to the
                                                <v-tooltip bottom>
                                                    <template
                                                        #activator="{
                                                            on
                                                        }"
                                                    >
                                                        <a
                                                            target="_blank"
                                                            href="http://static.app.ndsmartdata.com/other-assets/Smartdata-Terms-And-Conditions.pdf"
                                                            @click.stop
                                                            v-on="on"
                                                        >
                                                            Smartdata Usage
                                                            Terms
                                                        </a>
                                                    </template>
                                                    Opens in new window
                                                </v-tooltip>
                                            </div>
                                        </template>
                                    </v-checkbox>

                                    <br />
                                    <v-btn
                                        large
                                        color="primary"
                                        :loading="loader"
                                        @click="submitButton"
                                    >
                                        Register
                                        <v-icon
                                            right
                                            v-text="'$vuetify.icons.login'"
                                        />
                                    </v-btn>
                                </v-list-item>
                            </template>
                        </v-list>
                    </v-container>
                </v-form>
            </template>
        </v-card-text>

        <v-dialog v-model="dialog.HIBP" width="500">
            <v-card>
                <v-card-title class="headline" primary-title
                    >Why do we use HIBP?
                </v-card-title>
                <v-card-text class="pt-5">
                    <p>
                        Unfortunately some companies don't protect your password
                        effectively and hackers are able to steal this
                        information. Because we take security so seriously, we
                        check all passwords with a service called "Have I Been
                        Pwned" (HIBP). This service is run by a security
                        consultant who is well known and trusted in the
                        industry.
                    </p>
                    <p>
                        This service has a database of exposed personal
                        information and passwords. Your password is sent to the
                        HIBP service as a hash which means HIBP cannot directly
                        read it. By using this form, you agree to let us check
                        if it's been compromised by sending a hash to HIBP.
                    </p>
                    <p>
                        The following external links may be useful:<br />
                        <a
                            target="_blank"
                            href="https://haveibeenpwned.com/FAQs"
                            >Have I Been Pwned FAQs</a
                        ><br />
                        <a target="_blank" href="https://haveibeenpwned.com/"
                            >Have I Been Pwned Email Checker</a
                        ><br />
                        <a
                            target="_blank"
                            href="https://haveibeenpwned.com/Passwords"
                            >Have I Been Pwned Password Checker</a
                        >
                    </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary" text @click="HIBPDialog = false"
                        >Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialog.phone" width="500">
            <verification
                :email="user.email"
                :phone-number.sync="user.phoneNumber"
                :country-code.sync="user.countryCode"
                @phone-verified="phoneVerified"
                @close="dialog.phone = false"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import axios from 'axios';
import countriesList from 'components/Profile/PhoneNumber/countryCodes';
import Verification from 'components/Profile/PhoneNumber/RegisterPhoneVerification.vue';

export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Register',
    components: { Verification },
    props: {
        dark: {
            type: Boolean,
            default() {
                return false;
            }
        }
    },
    data() {
        return {
            user: {
                firstName: null,
                lastName: null,
                email: null,
                password1: null,
                password2: null,
                phoneNumber: null,
                countryCode: null,
                authyId: null
            },
            rules: {
                mustBeTrue: value => {
                    return (
                        value === true ||
                        'You must accept the terms and conditions.'
                    );
                },
                required: value => !!value || 'Required field',
                email: value => {
                    const pattern = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\d-A-Za-z]+\.)+[A-Za-z]{2,}))$/;
                    return pattern.test(value) || 'Must be a valid e-mail';
                },
                phoneNumber: [
                    value => !!value || 'Required field',
                    value => {
                        const pattern = /^\d*$/;
                        return pattern.test(value) || 'Numbers only.';
                    },
                    value =>
                        !value ||
                        value.length <= 10 ||
                        'Phone number cannot be longer than 10 numbers'
                ]
            },
            errors: {
                terms_checkbox: false,
                compromisedPassword: false,
                invalidFormFields: false,
                email: [],
                first_name: [],
                last_name: [],
                password1: [],
                password2: []
            },
            terms_checkbox: false,

            dialog: {
                HIBP: false,
                phone: false
            },
            showPassword: false,
            registrationSuccess: false,
            registrationError: false,
            errorMessage: null,
            loader: false,
            countryCodeArray: countriesList(),
            emailDisabled: false
        };
    },
    mounted() {
        const urlParameters = new URLSearchParams(window.location.search);
        const email = urlParameters.get('email');
        if (email) {
            this.emailDisabled = true;
            this.user.email = email;
        }
    },
    methods: {
        submitButton() {
            if (!this.terms_checkbox || !this.$refs.form.validate()) {
                this.errors.terms_checkbox = !this.terms_checkbox;
                return;
            }
            this.errors.invalidFormFields = false;
            this.errors.compromisedPassword = false;

            // TODO temp fix to remove authy
            this.submitRegistration();

            // first the user must complete phone number verification
            // if (!this.user.authyId) {
            //     this.dialog.phone = true;
            // } else {
            //     this.submitRegistration();
            // }
        },
        phoneVerified(authyId) {
            this.dialog.phone = false;
            this.user.authyId = authyId;
            this.submitRegistration();
        },
        submitRegistration() {
            this.loader = true;
            const postData = JSON.stringify({
                first_name: this.user.firstName,
                last_name: this.user.lastName,
                email: this.user.email,
                password1: this.user.password1,
                password2: this.user.password2,
                phoneNumber: this.user.phoneNumber,
                countryCode: this.user.countryCode,
                authyId: this.user.authyId
            });

            axios
                .post(`/api/accounts/register/`, postData)
                .then(response => response.data)
                .then(data => {
                    this.loader = false;
                    if (data.success) {
                        this.registrationError = false;
                        this.registrationSuccess = true;
                    } else {
                        const errors = JSON.parse(data.errors);
                        const keys = Object.keys(errors);

                        keys.forEach(key => {
                            this.errors[key] = errors[key].map(
                                error => error.message
                            );
                        });

                        if (
                            this.errors.password2.includes(
                                'Your password has been involved in a major security breach and cannot be used.'
                            )
                        ) {
                            this.errors.compromisedPassword = true;
                        }
                    }
                })
                .catch(error => {
                    this.registrationError = true;
                    this.errorMessage = `There was an unknown error registering your account. ${error}`;
                    throw error;
                });
        }
    }
};
</script>

<style scoped />
