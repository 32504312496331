<template>
    <v-card
        max-width="500"
        class="mx-auto text-center align-center justify-center"
    >
        <template v-if="!codeSent">
            <p class="pt-2" style="font-size: 1.5rem;">
                Verify Phone
            </p>
            <p
                v-if="!verificationError"
                class="pt-2"
                style="font-size: 1.0rem;"
            >
                {{ introText }} Click the button below to send a security code
                to {{ phoneNumber }} to verify your number.
            </p>
            <v-list-item v-if="verificationError">
                <v-alert outlined type="error" text>
                    {{ errorText }}
                </v-alert>
            </v-list-item>

            <v-list-item v-if="codeSendError">
                <v-alert outlined type="error" text>
                    There was an error sending the security code to your phone.
                    Please try again. If the error persists, contact Smartdata
                    support.
                </v-alert>
            </v-list-item>

            <v-layout justify-center pa-2>
                <v-btn
                    large
                    outlined
                    color="primary"
                    class="ma-2"
                    @click="close"
                >
                    Cancel
                </v-btn>
                <v-btn
                    large
                    color="primary"
                    :loading="loader"
                    class="ma-2"
                    @click="sendCode"
                >
                    Send Code
                </v-btn>
            </v-layout>
        </template>
        <template v-if="codeSent">
            <p class="pt-2" style="font-size: 1.5rem;">
                Verify Phone
            </p>
            <p class="pt-2" style="font-size: 1.0rem;">
                A security code has been sent to {{ phoneNumber }}. Please type
                it in below and click submit.
            </p>
            <v-form ref="form" @submit.prevent="submitToken">
                <v-container grid-list-xl>
                    <v-list-item v-if="verificationSuccess">
                        <v-alert outlined type="success" text>
                            Your phone number was successfully verified.
                        </v-alert>
                    </v-list-item>

                    <v-list-item v-if="verificationError">
                        <v-alert outlined type="error" text>
                            {{ errorText }}
                        </v-alert>
                    </v-list-item>

                    <v-list color="transparent" subheader two-line flat>
                        <v-list-item>
                            <v-text-field
                                v-model="code"
                                label="Security code"
                                :rules="rules.securityCode"
                                :counter="7"
                                prepend-icon="$vuetify.icons.lock"
                            >
                            </v-text-field>
                        </v-list-item>
                    </v-list>
                    <v-layout justify-center pa-2>
                        <v-btn
                            large
                            outlined
                            color="primary"
                            class="ma-2"
                            @click="close"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            large
                            color="primary"
                            :loading="loader"
                            class="ma-2"
                            @click="submitToken"
                        >
                            Submit
                        </v-btn>
                    </v-layout>
                </v-container>
            </v-form>
        </template>
    </v-card>
</template>

<script>
import axios from 'axios';

export default {
    name: 'Verification',
    props: {
        email: {
            type: String,
            default: '',
        },
        phoneNumber: {
            type: String,
            default: '',
        },
        countryCode: {
            type: String,
            default: '',
        },
        introText: {
            type: String,
            default:
                'Before you can complete your Smartdata registration you must verify your phone number.',
        },
    },
    data: function() {
        return {
            authyId: '',
            code: '',
            rules: {
                securityCode: [
                    value => {
                        const pattern = /^\d*$/;
                        return (
                            pattern.test(value) ||
                            'Security codes are numbers only.'
                        );
                    },
                    value => !!value || 'Required field',
                    value =>
                        value.length === 7 ||
                        'Security code must be 7 characters.',
                ],
            },
            loader: false,
            codeSent: false,
            verificationSuccess: false,
            verificationError: false,
            codeSendError: false,
            errorText: null,
        };
    },
    watch: {
        phoneNumber: {
            immediate: true,
            deep: true,
            handler() {
                this.reset();
            },
        },
        countryCode: {
            immediate: true,
            deep: true,
            handler() {
                this.reset();
            },
        },
    },
    mounted() {},
    ready: function() {
        this.init = this.$data;
    },
    created: function() {
        this.init = this.$data;
    },
    methods: {
        close() {
            this.reset();
            this.$emit('close');
        },
        reset() {
            this.loader = false;
            this.codeSent = false;
            this.verificationSuccess = false;
            this.verificationError = false;
            this.codeSendError = false;
            this.errorText = null;
            this.authyId = '';
            this.code = '';
        },
        submitToken() {
            if (!this.$refs.form.validate()) return;
            this.loader = true;
            axios
                .post(
                    '/api/accounts/authy_verify/',
                    JSON.stringify({
                        authyId: this.authyId,
                        token: this.code,
                    })
                )
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.code = '';
                        this.$emit('phone-verified', this.authyId);
                        this.reset();
                    } else {
                        this.codeSent = false;
                        this.verificationError = true;
                        this.code = '';
                        this.errorText =
                            'The security code was incorrect. Click the button below to send the code again.';
                    }
                })
                .catch(error => {
                    this.loader = false;
                    this.codeSent = false;
                    this.verificationError = true;
                    this.code = '';
                    this.errorText =
                        'There was an error verifying your code. Please try again.';
                    throw error;
                });
        },
        async sendCode() {
            this.loader = true;

            const authyUserCreated = await axios
                .post('/api/accounts/add_authy_user/', {
                    phone: this.phoneNumber,
                    email: this.email,
                    countryCode: this.countryCode,
                })
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.authyId = response.data.authyId;
                        return true;
                    } else {
                        this.codeSendError = true;
                        return false;
                    }
                })
                .catch(error => {
                    this.loader = false;
                    this.codeSendError = true;
                    throw error;
                });

            if (!authyUserCreated) return;

            axios
                .post('/api/accounts/authy_send_code/', {
                    authyId: this.authyId,
                })
                .then(response => {
                    this.loader = false;
                    if (response.status === 200) {
                        this.codeSent = true;
                        this.verificationError = false;
                    } else {
                        this.codeSendError = true;
                    }
                })
                .catch(error => {
                    this.codeSendError = true;
                    throw error;
                });
        },
    },
};
</script>

<style scoped />
