<template>
    <v-card flat class="tab-card" color="transparent">
        <v-card-title>
            <add-billing-account-administrator
                :add-id="billingAccountId"
                :title="'Current Administrators'"
                @userAdded="updateAdmins"
                @snackbar="showSnackbar"
            ></add-billing-account-administrator>
        </v-card-title>

        <v-container grid-list-md text-left>
            <v-progress-linear
                v-if="loading"
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-layout wrap>
                <v-flex
                    v-for="(admin, index) in admins"
                    :key="index"
                    pa-2
                    xs12
                    sm12
                    md6
                >
                    <v-card>
                        <v-list-item>
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    admin.name
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                    admin.email
                                }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-avatar>
                                <v-img :src="admin.profilePictureUrl"></v-img>
                            </v-list-item-avatar>
                        </v-list-item>

                        <v-card-actions>
                            <v-btn
                                :disabled="admin.isCurrentUser"
                                color="primary"
                                text
                                @click="
                                    removeAdmin(
                                        admin.name,
                                        admin.removeAdminUrl
                                    )
                                "
                                >Remove
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <generic-snack-bar ref="snackbar"></generic-snack-bar>
    </v-card>
</template>

<script>
import Vue from 'vue';
import axios from 'axios';
import AddBillingAccountAdministrator from 'components/BillingAccounts/AddBillingAccountAdministrator/AddBillingAccountAdministrator.vue';
import VuetifyConfirm from 'vuetify-confirm';
import GenericSnackBar from 'components/GenericSnackbar/GenericSnackBar.vue';

Vue.use(VuetifyConfirm);

export default {
    name: 'BillingAccountAdmins',
    components: {
        GenericSnackBar,
        AddBillingAccountAdministrator,
    },
    props: {
        billingAccountId: {
            default: null,
            type: String,
        },
    },
    data() {
        return {
            columns: ['Name', 'Email', 'Remove'],
            admins: [],
            loading: false,
        };
    },
    mounted() {
        this.updateAdmins();
    },
    methods: {
        updateAdmins() {
            this.loading = true;
            axios
                .get(`/api/billing_account/${this.billingAccountId}/get_admins`)
                .then(response => response.data)
                .then(data => {
                    this.admins = data.administrators;
                    this.loading = false;
                })
                .catch(() => {
                    this.$refs.snackbar.show(
                        'Could not update administrators due a to server error.'
                    );
                    this.loading = false;
                });
        },
        removeAdmin(name, url) {
            this.$confirm(
                `Are you sure you want to remove ${name} as an administrator`
            ).then(res => {
                if (res) {
                    this.loading = true;
                    axios
                        .get(url)
                        .then(response => response.data)
                        .then(data => {
                            this.$refs.snackbar.show(data.message);
                            this.updateAdmins();
                            this.loading = false;
                        })
                        .catch(() => {
                            this.$refs.snackbar.show(
                                'Could not remove this administrator due to a server error.'
                            );
                            this.loading = false;
                        });
                }
            });
        },
        showSnackbar(value) {
            this.$refs.snackbar.show(value);
        },
    },
};
</script>

<style scoped></style>
