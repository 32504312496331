import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VLayout,{class:{ 'pa-8': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"row":"","justify-center":""}},[(_vm.$vuetify.breakpoint.smAndDown)?_c(VSubheader,{staticClass:"my-2 full-width",on:{"click":_vm.profileClicked}},[_c(VBtn,{attrs:{"icon":""}},[_c(VIcon,{domProps:{"textContent":_vm._s('$vuetify.icons.prev')}})],1),_vm._v(" "),_c('div',{staticClass:"headline"},[_vm._v("Change Password")])],1):_vm._e(),_vm._v(" "),_c(VForm,{staticClass:"full-width"},[_c(VContainer,[_c(VList,{attrs:{"color":"transparent","subheader":"","two-line":"","flat":""}},[_c(VListItem,[_c('p',[_vm._v("\n                            Please fill out the form below to change your\n                            password. If you don't know what your current\n                            password is, you can reset it\n                            "),_c('a',{attrs:{"href":"/accounts/password/reset/request/"}},[_vm._v("here")]),_vm._v(".\n                        ")])]),_vm._v(" "),(_vm.passwordChanged)?_c(VListItem,[_c(VAlert,{attrs:{"dense":"","outlined":"","type":"success","text":""}},[_vm._v("\n                            Your password has been successfully changed.\n                        ")])],1):_vm._e(),_vm._v(" "),(_vm.passwordChangeError)?_c(VListItem,[_c(VAlert,{attrs:{"dense":"","outlined":"","type":"error","text":""}},[_vm._v("\n                            There was an error trying to change your\n                            password.\n                        ")])],1):_vm._e(),_vm._v(" "),_c(VListItem,[_c(VTextField,{attrs:{"disabled":_vm.disableForm,"label":"Current Password","prepend-inner-icon":"$vuetify.icons.lock","rules":[_vm.requiredRule],"append-icon":_vm.showPassword.old
                                    ? '$vuetify.icons.eye'
                                    : '$vuetify.icons.eyeSlash',"type":_vm.showPassword.old ? 'text' : 'password',"error":!!_vm.formErrors.old_password,"error-messages":_vm.formErrors.old_password || []},on:{"click:append":function($event){_vm.showPassword.old = !_vm.showPassword.old}},model:{value:(_vm.form.oldPassword),callback:function ($$v) {_vm.$set(_vm.form, "oldPassword", $$v)},expression:"form.oldPassword"}})],1),_vm._v(" "),_c(VListItem,[_c(VTextField,{attrs:{"disabled":_vm.disableForm,"label":"New Password","prepend-inner-icon":"$vuetify.icons.shield","rules":[_vm.requiredRule],"append-icon":_vm.showPassword.new1
                                    ? '$vuetify.icons.eye'
                                    : '$vuetify.icons.eyeSlash',"type":_vm.showPassword.new1 ? 'text' : 'password',"error":!!_vm.formErrors.new_password1,"error-messages":_vm.formErrors.new_password1 || []},on:{"click:append":function($event){_vm.showPassword.new1 = !_vm.showPassword.new1}},model:{value:(_vm.form.newPassword1),callback:function ($$v) {_vm.$set(_vm.form, "newPassword1", $$v)},expression:"form.newPassword1"}})],1),_vm._v(" "),_c(VListItem,[_c(VTextField,{attrs:{"disabled":_vm.disableForm,"label":"Confirm New Password","prepend-inner-icon":"$vuetify.icons.shield","rules":[_vm.requiredRule],"append-icon":_vm.showPassword.new2
                                    ? '$vuetify.icons.eye'
                                    : '$vuetify.icons.eyeSlash',"type":_vm.showPassword.new2 ? 'text' : 'password',"error":!!_vm.formErrors.new_password2,"error-messages":_vm.formErrors.new_password2},on:{"click:append":function($event){_vm.showPassword.new2 = !_vm.showPassword.new2}},model:{value:(_vm.form.newPassword2),callback:function ($$v) {_vm.$set(_vm.form, "newPassword2", $$v)},expression:"form.newPassword2"}})],1)],1),_vm._v(" "),_c(VLayout,{staticClass:"mt-5",attrs:{"justify-center":""}},[_c(VBtn,{attrs:{"disabled":_vm.disableForm,"large":"","color":"primary"},on:{"click":_vm.submitChangePassword}},[_vm._v("Change Password"),_c(VIcon,{attrs:{"right":""},domProps:{"textContent":_vm._s('$vuetify.icons.next')}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }