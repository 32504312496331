<template>
    <v-card max-width="450" class="mx-auto text-center">
        <v-card-text>
            <template>
                <p class="pt-2" style="font-size: 1.5rem;">
                    Login to continue
                </p>
                <v-form ref="form" v-model="login.formValid">
                    <v-container grid-list-xl>
                        <v-layout wrap>
                            <v-flex v-if="emailVerificationSuccess" xs12>
                                <v-alert outlined type="success" text>
                                    Your email address has been successfully
                                    verified. You may now login.
                                </v-alert>
                            </v-flex>

                            <v-flex v-if="emailVerificationError" xs12>
                                <v-alert outlined type="error" text>
                                    There was an error verifying your email
                                    address. Please try again.
                                </v-alert>
                            </v-flex>

                            <v-flex v-if="login.loginSuccess" xs12>
                                <v-alert outlined type="success" text>
                                    Login successful!
                                </v-alert>
                            </v-flex>

                            <v-flex v-if="login.loginInvalidCredentials" xs12>
                                <v-alert outlined type="error" text>
                                    {{ login.invalidCredentialsMsg }}
                                </v-alert>
                            </v-flex>

                            <v-flex v-if="login.genericError" xs12>
                                <v-alert outlined type="error" text>
                                    Unknown error while trying to login.
                                </v-alert>
                            </v-flex>

                            <v-flex v-if="login.unverifiedEmail" xs12>
                                <v-alert outlined type="warning" text>
                                    Email is not verified, you must verify your
                                    email before you can login.
                                </v-alert>
                                <v-btn color="primary" text @click="resendEmail"
                                    >Resend Email</v-btn
                                >
                            </v-flex>
                            <!--                            CESIUM REPORT GENERATION IS DEPENDENT ON THESE TEXT FIELDS-->
                            <v-flex xs12>
                                <v-text-field
                                    id="email-field"
                                    v-model="login.email"
                                    autofocus
                                    :hide-details="true"
                                    label="Email Address"
                                    prepend-inner-icon="$vuetify.icons.email"
                                    :rules="[rules.required, rules.email]"
                                    filled
                                    type="email"
                                    name="email"
                                />
                            </v-flex>

                            <v-flex xs12>
                                <v-text-field
                                    id="password-field"
                                    v-model="login.password"
                                    :hide-details="true"
                                    prepend-inner-icon="$vuetify.icons.lock"
                                    :append-icon="
                                        login.showPassword
                                            ? '$vuetify.icons.eye'
                                            : '$vuetify.icons.eyeSlash'
                                    "
                                    :type="
                                        login.showPassword ? 'text' : 'password'
                                    "
                                    name="password"
                                    label="Password"
                                    class="input-group--focused"
                                    :rules="[rules.required]"
                                    filled
                                    @click:append="
                                        login.showPassword = !login.showPassword
                                    "
                                />
                            </v-flex>

                            <v-flex xs12>
                                <p class="mb-0">
                                    Don't have an account yet?
                                    <a href="/accounts/register/"
                                        >Register Account</a
                                    ><br />
                                    Forgot your password?
                                    <a href="/accounts/password/reset/request/"
                                        >Reset It</a
                                    >
                                </p>
                            </v-flex>

                            <v-flex xs12>
                                <v-btn
                                    large
                                    color="primary"
                                    :loading="loader"
                                    @click="submitLogin"
                                >
                                    Login
                                    <v-icon
                                        right
                                        v-text="'$vuetify.icons.login'"
                                    />
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-container>
                </v-form>
            </template>
        </v-card-text>

        <generic-snack-bar ref="snackbar" />

        <two-factor-authentication
            ref="twoFactorDialog"
            @token-ready="completeTwoFactorLogin"
        />
    </v-card>
</template>

<script>
import axios from 'axios';
import GenericSnackBar from 'components/GenericSnackbar/GenericSnackBar.vue';
import TwoFactorAuthentication from 'components/Profile/PhoneNumber/LoginPhoneVerification.vue';

export default {
    name: 'LoginCard',
    components: { TwoFactorAuthentication, GenericSnackBar },
    props: {
        emailVerification: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            rules: {
                required: value => !!value || 'Required field',
                email: value => {
                    const pattern = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\d-A-Za-z]+\.)+[A-Za-z]{2,}))$/;
                    return pattern.test(value) || 'Must be a valid e-mail';
                }
            },
            login: {
                email: null,
                password: null,
                showPassword: false,
                formValid: null,
                loginSuccess: false,
                loginInvalidCredentials: false,
                genericError: false,
                unverifiedEmail: false,
                resendEmailLink: null,
                invalidCredentialsMsg: 'Incorrect email or password.'
            },
            emailVerificationSuccess: false,
            emailVerificationError: false,
            loader: false,
            genericErrorMessage: 'Unknown error while trying to login.',
            authyId: ''
        };
    },
    mounted() {
        const that = this;

        window.addEventListener('keyup', event => {
            if (event.key === 'Enter') {
                that.submitLogin();
            }
        });

        // eslint-disable-next-line eqeqeq
        if (this.emailVerification != undefined) {
            // eslint-disable-next-line eqeqeq
            if (this.emailVerification == 'True') {
                this.emailVerificationSuccess = true;
                // eslint-disable-next-line eqeqeq
            } else if (this.emailVerification == 'False') {
                this.emailVerificationError = true;
            }
        }
    },
    methods: {
        completeTwoFactorLogin(authyToken) {
            this.$refs.twoFactorDialog.close();
            this.loader = true;

            axios
                .post(
                    '/accounts/login/',
                    JSON.stringify({
                        email: this.login.email,
                        password: this.login.password,
                        token: authyToken
                    })
                )
                .then(response => {
                    if (response.data.twoFactorFailed) {
                        this.$refs.twoFactorDialog.init(authyToken, true);
                    } else if (response.data.success) {
                        this.loginSuccess();
                    }
                })
                .catch(error => {
                    window.dialog(
                        'Sorry but there was an error logging in. Please try again later or contact support.'
                    );
                    throw error;
                });
        },
        loginSuccess() {
            this.login.loginSuccess = true;
            const urlParameters = new URLSearchParams(window.location.search);
            const redirectTo = urlParameters.get('next');

            if (redirectTo != null) {
                document.location = redirectTo;
            } else {
                document.location = '/';
            }
        },
        submitLogin() {
            if (!this.$refs.form.validate()) return;

            this.loader = true;
            this.turnOffMessages();
            axios
                .post(
                    '/accounts/login/',
                    JSON.stringify({
                        email: this.login.email,
                        password: this.login.password
                    })
                )
                .then(response => {
                    this.loader = false;
                    if (response.data.success === true) {
                        this.loginSuccess();
                    } else if (response.data.unverifiedEmail) {
                        this.login.unverifiedEmail = true;
                        this.login.resendEmailLink =
                            response.data.resendEmailLink;
                    } else if (response.data.loginInvalidCredentials) {
                        this.login.loginInvalidCredentials = true;
                        this.login.invalidCredentialsMsg =
                            response.data.message;
                    } else if (response.data.twoFactorRequired) {
                        this.authyId = response.data.authyId;
                        this.$refs.twoFactorDialog.init(this.authyId);
                    }
                })
                .catch(error => {
                    this.loader = false;
                    this.login.genericError = true;
                    throw error;
                });
        },
        resendEmail() {
            axios
                .get(this.login.resendEmailLink)
                .then(response => response.data)
                .then(data => {
                    if (data.success) {
                        this.$refs.snackbar.show(
                            'The verification email has been resent.'
                        );
                    } else {
                        this.$refs.snackbar.show(
                            'There was an error resending the email.'
                        );
                    }
                })
                .catch(error => {
                    this.$refs.snackbar.show(
                        'There was an error resending the email.'
                    );
                    throw error;
                });
        },
        turnOffMessages() {
            // call before displaying a message to get rid of the other messages
            this.login.loginInvalidCredentials = false;
            this.emailVerificationError = false;
            this.emailVerificationSuccess = false;
            this.login.loginSuccess = false;
            this.login.unverifiedEmail = false;
            this.login.genericError = false;
        }
    }
};
</script>
