<template>
    <v-container>
        <v-layout
            row
            justify-center
            :class="{ 'pa-8': $vuetify.breakpoint.mdAndUp }"
        >
            <v-subheader
                v-if="$vuetify.breakpoint.smAndDown"
                class="my-2 full-width"
                @click="profileClicked"
            >
                <v-btn icon>
                    <v-icon v-text="'$vuetify.icons.prev'"></v-icon>
                </v-btn>
                <div class="headline">Change Password</div>
            </v-subheader>

            <v-form class="full-width">
                <v-container>
                    <v-list color="transparent" subheader two-line flat>
                        <v-list-item>
                            <p>
                                Please fill out the form below to change your
                                password. If you don't know what your current
                                password is, you can reset it
                                <a href="/accounts/password/reset/request/"
                                    >here</a
                                >.
                            </p>
                        </v-list-item>

                        <v-list-item v-if="passwordChanged">
                            <v-alert dense outlined type="success" text>
                                Your password has been successfully changed.
                            </v-alert>
                        </v-list-item>

                        <v-list-item v-if="passwordChangeError">
                            <v-alert dense outlined type="error" text>
                                There was an error trying to change your
                                password.
                            </v-alert>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.oldPassword"
                                :disabled="disableForm"
                                label="Current Password"
                                prepend-inner-icon="$vuetify.icons.lock"
                                :rules="[requiredRule]"
                                :append-icon="
                                    showPassword.old
                                        ? '$vuetify.icons.eye'
                                        : '$vuetify.icons.eyeSlash'
                                "
                                :type="showPassword.old ? 'text' : 'password'"
                                :error="!!formErrors.old_password"
                                :error-messages="formErrors.old_password || []"
                                @click:append="
                                    showPassword.old = !showPassword.old
                                "
                            ></v-text-field>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.newPassword1"
                                :disabled="disableForm"
                                label="New Password"
                                prepend-inner-icon="$vuetify.icons.shield"
                                :rules="[requiredRule]"
                                :append-icon="
                                    showPassword.new1
                                        ? '$vuetify.icons.eye'
                                        : '$vuetify.icons.eyeSlash'
                                "
                                :type="showPassword.new1 ? 'text' : 'password'"
                                :error="!!formErrors.new_password1"
                                :error-messages="formErrors.new_password1 || []"
                                @click:append="
                                    showPassword.new1 = !showPassword.new1
                                "
                            ></v-text-field>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.newPassword2"
                                :disabled="disableForm"
                                label="Confirm New Password"
                                prepend-inner-icon="$vuetify.icons.shield"
                                :rules="[requiredRule]"
                                :append-icon="
                                    showPassword.new2
                                        ? '$vuetify.icons.eye'
                                        : '$vuetify.icons.eyeSlash'
                                "
                                :type="showPassword.new2 ? 'text' : 'password'"
                                :error="!!formErrors.new_password2"
                                :error-messages="formErrors.new_password2"
                                @click:append="
                                    showPassword.new2 = !showPassword.new2
                                "
                            ></v-text-field>
                        </v-list-item>
                    </v-list>

                    <v-layout class="mt-5" justify-center>
                        <v-btn
                            :disabled="disableForm"
                            large
                            color="primary"
                            @click="submitChangePassword"
                            >Change Password<v-icon
                                right
                                v-text="'$vuetify.icons.next'"
                            ></v-icon
                        ></v-btn>
                    </v-layout>
                </v-container>
            </v-form>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import { trackProfileEvents } from 'js/eventsTracking';

export default {
    name: 'PasswordChange',
    data() {
        return {
            disableForm: false,
            form: {
                newPassword1: null,
                newPassword2: null,
                oldPassword: null,
                valid: null,
            },
            requiredRule: value => !!value || 'Required field',
            passwordChanged: false,
            passwordChangeError: false,
            showPassword: {
                new1: false,
                new2: false,
                old: false,
            },
            formErrors: {},
        };
    },
    methods: {
        submitChangePassword() {
            this.formErrors = {};
            axios
                .post(
                    `${window.location.origin}/api/accounts/password/change/`,
                    {
                        new_password1: this.form.newPassword1,
                        new_password2: this.form.newPassword2,
                        old_password: this.form.oldPassword,
                    }
                )
                .then(response => response.data)
                .then(data => {
                    if (data.success) {
                        trackProfileEvents.changedPassword();
                        this.passwordChanged = true;
                        this.passwordChangeError = false;
                        this.disableForm = true;
                    } else {
                        this.passwordChangeError = true;
                        this.passwordChanged = false;
                    }
                })
                .catch(error => {
                    this.passwordChanged = false;
                    this.passwordChangeError = true;
                    this.formErrors =
                        error.response.status === 400
                            ? error.response.data.errors
                            : {};
                });
        },
        profileClicked() {
            this.$emit('tab-navigation', 'profile');
        },
    },
};
</script>

<style scoped></style>
