<template>
    <span>
        <v-layout wrap>
            <div v-show="$vuetify.breakpoint.mdAndUp" class="top-bar-bg">
                <div class="top-bar-container">
                    <div class="headline">Profile</div>
                    <v-tabs v-model="currentTab" background-color="transparent">
                        <v-tab key="profile">
                            Summary
                        </v-tab>
                        <v-tab key="edit">
                            Edit
                        </v-tab>
                        <v-tab key="password">
                            Change Password
                        </v-tab>
                        <v-tab key="notifications">
                            Settings
                        </v-tab>
                    </v-tabs>
                </div>
                <v-divider />
            </div>

            <v-tabs-items
                v-model="currentTab"
                class="full-width"
                style="background-color: transparent;"
            >
                <v-tab-item key="profile">
                    <my-profile
                        ref="myProfile"
                        :profile="profile.profile"
                        @tab-navigation="tabNavigation"
                    />
                </v-tab-item>

                <v-tab-item key="edit">
                    <profile-form
                        :profile="profile.profile"
                        @tab-navigation="tabNavigation"
                        @profile-updated="profileUpdated"
                    />
                </v-tab-item>

                <v-tab-item key="password">
                    <change-password @tab-navigation="tabNavigation" />
                </v-tab-item>

                <v-tab-item key="notifications">
                    <manage-notifications
                        :profile="profile.profile"
                        :user-settings="profile.userSettings"
                        :timezone-array="initialProfile.timezoneArray"
                        @tab-navigation="tabNavigation"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-layout>
    </span>
</template>

<script>
import myProfile from 'components/Profile/ProfilePage/MyProfile/MyProfile.vue';
import changePassword from 'components/Profile/ProfilePage/PasswordChange/PasswordChange.vue';
import profileForm from 'components/Profile/ProfilePage/ProfileForm/ProfileForm.vue';
import manageNotifications from 'components/Profile/ProfilePage/ProfileSettings/ProfileSettings.vue';

export default {
    name: 'ProfilePage',
    components: {
        profileForm,
        manageNotifications,
        myProfile,
        changePassword,
    },
    props: {
        initialProfile: {
            type: Object,
            default: null,
        },
    },
    data() {
        return {
            currentTab: null,
            profile: {},
        };
    },
    mounted() {
        this.profile = this.initialProfile;
    },
    methods: {
        tabNavigation(value) {
            if (value === 'profile') this.currentTab = 0;
            else if (value === 'edit') this.currentTab = 1;
            else if (value === 'password') this.currentTab = 2;
            else if (value === 'notifications') this.currentTab = 3;
        },
    },
};
</script>

<style scoped />
