<template>
    <v-flex>
        <v-tabs-items
            v-model="currentTab"
            class="full-width"
            style="background-color: transparent"
        >
            <v-tab-item key="myBillingAccounts">
                <v-card
                    flat
                    class="tab-card"
                    color="transparent"
                    justify-center
                >
                    <v-container grid-list-md text-left>
                        <v-subheader class="headline"
                            >Billing Accounts</v-subheader
                        >
                        <v-layout wrap justify center>
                            <v-flex
                                v-for="(billingAccount,
                                index) in billingAccounts"
                                :key="index"
                                xs12
                                sm6
                                md4
                                pa-2
                            >
                                <v-card>
                                    <v-card-title>
                                        {{ billingAccount.companyName }}
                                    </v-card-title>

                                    <v-card-text>
                                        {{ billingAccount.contactName }}
                                        <br />
                                        {{ billingAccount.billingEmail }}
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-btn
                                            color="primary"
                                            text
                                            :href="billingAccount.url"
                                        >
                                            Open Billing Account
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-flex>

                            <v-flex
                                v-if="$vuetify.breakpoint.mdAndUp"
                                xs12
                                sm6
                                md4
                                pa-2
                            >
                                <v-btn
                                    v-if="dark"
                                    style="height: 168px; width: 100%;"
                                    color="grey darken-3"
                                    @click="openCreateForm"
                                >
                                    <v-container fill-height fluid>
                                        <v-row
                                            align="center"
                                            justify="center"
                                            class="subtitle-1 text-uppercase"
                                        >
                                            <v-icon>
                                                {{
                                                    '$vuetify.icons.plus'
                                                }}</v-icon
                                            >&nbsp;&nbsp; New Billing Account
                                        </v-row>
                                    </v-container>
                                </v-btn>
                                <v-btn
                                    v-else
                                    style="height: 168px; width: 100%;"
                                    color="white"
                                    @click="openCreateForm"
                                >
                                    <v-container fill-height fluid>
                                        <v-row
                                            align="center"
                                            justify="center"
                                            class="subtitle-1 text-uppercase"
                                        >
                                            <v-icon>
                                                {{
                                                    '$vuetify.icons.plus'
                                                }}</v-icon
                                            >&nbsp;&nbsp; New Billing Account
                                        </v-row>
                                    </v-container>
                                </v-btn>
                            </v-flex>
                            <v-btn
                                v-else
                                fixed
                                dark
                                fab
                                bottom
                                right
                                color="primary"
                                @click="openCreateForm"
                            >
                                <v-icon>{{ '$vuetify.icons.plus' }}</v-icon>
                            </v-btn>
                        </v-layout>
                    </v-container>
                </v-card>
            </v-tab-item>
            <v-tab-item key="newBillingAccount">
                <v-card>
                    <billing-account-form
                        @tab-navigation="currentTab = 0"
                    ></billing-account-form>
                </v-card>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog
            v-model="showCreateForm"
            max-width="600px"
            hide-overlay
            color="grey darken-3"
        >
            <v-card class="elevation-0 text-center pt-5">
                <v-row style="margin: 0;">
                    <div class="full-width headline">
                        Create New Billing Account
                    </div>
                    <v-btn
                        text
                        icon
                        class="dialog-close-btn"
                        @click="showCreateForm = false"
                    >
                        <v-icon>$vuetify.icons.close</v-icon>
                    </v-btn>
                </v-row>
                <billing-account-form
                    @tab-navigation="currentTab = 0"
                    @cancel="showCreateForm = false"
                ></billing-account-form>
            </v-card>
        </v-dialog>
    </v-flex>
</template>

<script>
import BillingAccountForm from 'components/BillingAccounts/BillingAccountForm/BillingAccountForm.vue';

export default {
    name: 'MyBillingAccounts',
    components: {
        BillingAccountForm,
    },
    props: {
        dark: false,
        billingAccounts: {
            type: Array,
            default: [],
        },
    },
    data: function() {
        return {
            currentTab: 0,
            showCreateForm: false,
        };
    },
    methods: {
        openCreateForm() {
            if (this.$vuetify.breakpoint.mdAndUp) {
                this.showCreateForm = true;
            } else {
                this.currentTab = 1;
            }
        },
    },
};
</script>

<style scoped></style>
