<template>
    <v-container>
        <v-layout
            row
            justify-center
            :class="{ 'pa-8': $vuetify.breakpoint.mdAndUp }"
        >
            <v-subheader
                v-if="$vuetify.breakpoint.smAndDown"
                class="my-2 full-width"
                @click="profileClicked"
            >
                <v-btn icon>
                    <v-icon v-text="'$vuetify.icons.prev'"></v-icon>
                </v-btn>
                <div class="headline">Edit Profile</div>
            </v-subheader>

            <v-form
                ref="form"
                v-model="valid"
                class="full-width"
                lazy-validation
            >
                <v-container>
                    <v-list color="transparent" subheader two-line flat>
                        <v-list-item v-if="!verified">
                            <v-alert width="100%" dense type="warning" text>
                                You must verify changes to your email.
                            </v-alert>
                        </v-list-item>

                        <v-list-item v-if="error.show">
                            <v-alert outlined type="error" text>
                                {{ error.message }}
                            </v-alert>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.email"
                                label="Email"
                                required
                                prepend-icon="$vuetify.icons.email"
                                class="pb-4"
                                persistent-hint
                                :rules="rules.email"
                                :hint="
                                    verified
                                        ? 'Email is verified!'
                                        : 'Email is not verified!'
                                "
                                @input="emailChange"
                            >
                            </v-text-field>
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.firstName"
                                label="First Name"
                                required
                                :rules="rules.firstName"
                                prepend-icon="$vuetify.icons.profile"
                                @input="formChange"
                            />
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.lastName"
                                label="Last Name"
                                required
                                :rules="rules.lastName"
                                prepend-icon="$vuetify.icons.profile"
                                @input="formChange"
                            />
                        </v-list-item>

                        <v-list-item>
                            <v-autocomplete
                                v-model="form.countryCode"
                                label="Country code"
                                required
                                :items="countryCodeArray"
                                item-text="country"
                                item-value="code"
                                prepend-icon="$vuetify.icons.phone"
                                autocomplete="off"
                                @change="formChange"
                            />
                        </v-list-item>

                        <v-list-item>
                            <v-text-field
                                v-model="form.phoneNumber"
                                :rules="rules.phoneNumber"
                                :counter="10"
                                label="Phone number"
                                prepend-icon="$vuetify.icons.mobile"
                                @change="formChange"
                            />
                        </v-list-item>

                        <v-list-item>
                            <v-file-input
                                v-if="renderComponent"
                                label="Profile Picture"
                                type="file"
                                accept="image/jpeg"
                                prepend-icon="$vuetify.icons.image"
                                :rules="rules.profilePictureRules"
                                show-size
                                @change="inputChange"
                            ></v-file-input>
                        </v-list-item>

                        <v-list-item>
                            <template v-if="!profile.usingGravatar">
                                <v-btn
                                    text
                                    color="primary"
                                    class="mx-auto"
                                    large
                                    height="50px"
                                    :loading="removePictureButtonLoading"
                                    @click="removeProfilePicture"
                                >
                                    <v-img
                                        max-width="50px"
                                        max-height="50px"
                                        :src="profile.profilePictureUrl"
                                        mr-2
                                        style="margin-right: 10px;"
                                    ></v-img>
                                    Remove Profile Picture
                                </v-btn>
                            </template>
                            <template v-else>
                                <v-alert
                                    :icon="'$vuetify.icons.infoCircleReg'"
                                    type="info"
                                    class="text-center"
                                >
                                    If you have a gravatar account, we'll
                                    automatically use it for your profile image.
                                    Alternatively, you can upload one directly
                                    to Smartdata by clicking above.
                                </v-alert>
                            </template>
                        </v-list-item>
                    </v-list>

                    <v-layout justify-center>
                        <v-btn large text color="primary" @click="clear"
                            >Cancel</v-btn
                        >
                        <v-spacer />
                        <v-btn
                            large
                            color="primary"
                            :loading="buttonLoading"
                            :disabled="buttonDisable"
                            @click="submitButton"
                            >Save<v-icon
                                right
                                v-text="'$vuetify.icons.next'"
                            ></v-icon
                        ></v-btn>
                    </v-layout>
                </v-container>
            </v-form>
            <v-dialog v-model="dialog.phone" width="500" persistent>
                <verification
                    :email="form.email"
                    :phone-number="form.phoneNumber"
                    :country-code="form.countryCode"
                    :intro-text="dialog.text"
                    @phone-verified="newPhoneVerified"
                    @close="closePhoneDialog"
                />
            </v-dialog>
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import countriesList from 'components/Profile/PhoneNumber/countryCodes';
import Verification from 'components/Profile/PhoneNumber/RegisterPhoneVerification.vue';
import { trackProfileEvents } from 'js/eventsTracking';

export default {
    name: 'ProfileForm',
    components: {
        Verification,
    },
    props: {
        profile: {
            type: Object,
            default: () => {
                return {
                    email: null,
                    firstName: null,
                    lastName: null,
                    profilePictureUrl: null,
                    timezone: null,
                };
            },
        },
    },
    data: () => {
        return {
            dialog: {
                phone: false,
                text: 'Before you can change your number you must verify it.',
            },
            buttonDisable: true,
            valid: false,
            form: {
                email: null,
                firstName: null,
                lastName: null,
                phoneNumber: null,
                countryCode: null,
            },
            profilePictureInput: null,
            verified: null,
            rules: {
                email: [
                    value => {
                        const pattern = /^(([^\s"(),.:;<>@[\\\]]+(\.[^\s"(),.:;<>@[\\\]]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([\d-A-Za-z]+\.)+[A-Za-z]{2,}))$/;
                        return pattern.test(value) || 'Must be a valid e-mail';
                    },
                    value => !!value || 'Email is required.',
                ],
                firstName: [value => !!value || 'First name is required.'],
                lastName: [value => !!value || 'Last name is required.'],
                profilePictureRules: [
                    value =>
                        !value ||
                        !value.size ||
                        value.size <= 1000000 ||
                        'File size must be less than 1 MB!',
                    value =>
                        !value ||
                        !value.type ||
                        value.type === 'image/jpeg' ||
                        'File must be a .jpg',
                ],
                phoneNumber: [
                    value => {
                        const pattern = /^\d*$/;
                        return pattern.test(value) || 'Numbers only.';
                    },
                    value =>
                        !value ||
                        value.length <= 10 ||
                        'Phone number cannot be longer than 10 numbers',
                ],
            },
            buttonLoading: false,
            error: {
                show: false,
                message: '',
            },
            renderComponent: true,
            removePictureButtonLoading: false,
            countryCodeArray: countriesList(),
            errorSavingChangesMsg:
                'There was an error saving your changes, please try again later or contact support.',
        };
    },
    mounted() {
        if (this.profile) {
            this.form.firstName = this.profile.firstName;
            this.form.lastName = this.profile.lastName;
            this.form.email = this.profile.email;
            this.form.countryCode = this.profile.countryCode;
            this.form.phoneNumber = this.profile.phoneNumber;
            this.verified = this.profile.verified;
            this.form.authyId = this.profile.authyId;
            Object.assign(this.form, this.profile);
        } else {
            this.refreshValues();
        }
    },
    methods: {
        closePhoneDialog() {
            this.dialog.phone = false;
            this.form.phoneNumber = this.profile.phoneNumber;
            this.form.countryCode = this.profile.countryCode;
        },
        profileClicked() {
            this.$emit('tab-navigation', 'profile');
        },
        submitButton() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.error.show = false;

            // If a number and country is supplied and it is different to the user's existing one
            if (
                this.form.countryCode &&
                this.form.phoneNumber &&
                (this.profile.countryCode !== this.form.countryCode ||
                    this.profile.phoneNumber !== this.form.phoneNumber)
            ) {
                // verify new phone number
                this.dialog.phone = true;
            } else {
                this.submit();
            }
        },
        newPhoneVerified(authyId) {
            this.dialog.phone = false;
            this.form.authyId = authyId;
            this.submit();
        },
        submit() {
            if (!this.$refs.form.validate()) {
                return;
            }
            this.buttonLoading = true;

            const formData = new FormData();
            formData.append('data', JSON.stringify({ profile: this.form }));
            formData.append('file', this.profilePictureInput);

            axios
                .post(`/api/accounts/profile/edit/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(response => response.data)
                .then(data => {
                    this.buttonLoading = false;

                    if (data.success) {
                        trackProfileEvents.editedProfile();
                        this.buttonDisable = true;
                        this.$emit('profile-updated');

                        if (this.profilePictureInput) {
                            this.profilePictureInput = null;
                            this.forceRefreshProfilePictureInput();
                        }

                        Object.assign(this.profile, data.profile);
                        Object.assign(this.form, this.profile);
                    } else {
                        this.error.message = data.emailAlreadyExists
                            ? 'A user already exists with this email address.'
                            : this.errorSavingChangesMsg;
                        this.error.show = true;
                    }
                })
                .catch(error => {
                    this.showError();
                    throw error;
                });
        },
        showError() {
            this.buttonLoading = false;
            this.error.message = this.errorSavingChangesMsg;
            this.error.show = true;
        },
        clear() {
            this.error.show = false;
            this.refreshValues();
            this.buttonDisable = true;
        },
        refreshValues() {
            axios
                .get(`/api/accounts/profile/edit/`)
                .then(response => response.data)
                .then(data => {
                    Object.assign(this.profile, data.profile);
                    Object.assign(this.form, this.profile);
                })
                .catch(() => {
                    this.error.message = this.errorSavingChangesMsg;
                });
        },
        formChange() {
            this.buttonDisable = false;
        },
        emailChange() {
            this.verified = false;
            this.buttonDisable = false;
        },
        inputChange(value) {
            this.profilePictureInput = value;
            this.formChange();
        },
        forceRefreshProfilePictureInput() {
            // Clearing the file input this way because it is read only and modifying produces bugs
            this.renderComponent = false;
            // eslint-disable-next-line promise/catch-or-return
            this.$nextTick().then(() => {
                this.renderComponent = true;
            });
        },
        removeProfilePicture() {
            this.error.show = false;
            this.removePictureButtonLoading = true;

            axios
                .post(`/api/accounts/profile/remove_profile_picture/`)
                .then(response => response.data)
                .then(data => {
                    this.removePictureButtonLoading = false;
                    if (data.success) {
                        this.form.firstName = data.profile.firstName;
                        this.form.lastName = data.profile.lastName;
                        this.form.email = data.profile.email;
                        Object.assign(this.profile, data.profile);
                    } else {
                        this.error.message = this.errorSavingChangesMsg;
                        this.error.show = true;
                    }
                })
                .catch(error => {
                    this.showError();
                    throw error;
                });
        },
    },
};
</script>

<style scoped>
.v-messages__message {
    color: red;
}
</style>
