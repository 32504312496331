import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[_c('div',[_c('div',{staticClass:"top-bar-container"},[_c(VBreadcrumbs,{attrs:{"items":_vm.breadCrumbs,"divider":"/"}}),_vm._v(" "),_c(VTabs,{attrs:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTab,{key:"contactDetails"},[_vm._v("Contact Details")]),_vm._v(" "),_c(VTab,{key:"planAndBilling"},[_vm._v("Plan & Billing")]),_vm._v(" "),_c(VTab,{key:"accountAdministrators"},[_vm._v("Administrators ")])],1)],1),_vm._v(" "),_c(VDivider)],1),_vm._v(" "),_c(VTabsItems,{staticClass:"full-width",staticStyle:{"background-color":"transparent"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c(VTabItem,{key:"contactDetails"},[_c('billing-account-form',{attrs:{"billing-account-id":_vm.billingAccountId}})],1),_vm._v(" "),_c(VTabItem,{key:"planAndBilling"},[_c('payment-update-form',{attrs:{"billing-account-id":_vm.billingAccountId,"billing-plans":_vm.billingPlans}})],1),_vm._v(" "),_c(VTabItem,{key:"accountAdministrators"},[_c('billing-account-administrators',{attrs:{"billing-account-id":_vm.billingAccountId}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }