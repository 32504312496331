<template>
    <v-flex>
        <v-alert
            v-if="!myProfile.verified"
            outlined
            type="warning"
            text
            width=""
        >
            You must verify this email before you can login.
        </v-alert>
        <template v-if="$vuetify.breakpoint.smAndDown">
            <v-card flat tile class="ma-0" width="100%">
                <v-img :src="profile.profilePictureUrl" dark>
                    <v-layout column fill-height>
                        <v-card-title>
                            <v-spacer></v-spacer>

                            <v-btn dark icon @click="editClicked">
                                <v-icon v-text="'$vuetify.icons.edit'"></v-icon>
                            </v-btn>

                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn dark icon v-on="on">
                                        <v-icon
                                            v-text="
                                                '$vuetify.icons.moreOptions'
                                            "
                                        ></v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-title
                                            @click="passwordClicked"
                                            >Change Password</v-list-item-title
                                        >
                                    </v-list-item>
                                    <v-list-item>
                                        <v-list-item-title
                                            @click="notificationsClicked"
                                            >Notification Preferences
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </v-card-title>

                        <v-spacer></v-spacer>

                        <v-card-title class="white--text mx-auto">
                            <div class="display-1">
                                {{ myProfile.firstName }}
                                {{ myProfile.lastName }}
                            </div>
                        </v-card-title>
                    </v-layout>
                </v-img>

                <v-list two-line>
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon v-text="'$vuetify.icons.email'"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                myProfile.email
                            }}</v-list-item-title>
                            <v-list-item-subtitle>Email</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon v-text="'$vuetify.icons.phone'"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title
                                >+ {{ myProfile.countryCode }}
                                {{ myProfile.phoneNumber }}</v-list-item-title
                            >
                            <v-list-item-subtitle>Phone</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item v-if="myProfile.permissionLevel !== 'User'">
                        <v-list-item-icon>
                            <v-icon v-text="'$vuetify.icons.userLock'"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                myProfile.permissionLevel
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                                >Permission Level</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon v-text="'$vuetify.icons.clock'"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                myProfile.lastLogin
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                                >Last Login</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>

                    <v-divider inset></v-divider>

                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon v-text="'$vuetify.icons.calendar'"></v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                myProfile.dateJoined
                            }}</v-list-item-title>
                            <v-list-item-subtitle
                                >Date Joined</v-list-item-subtitle
                            >
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-card>
        </template>

        <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-container>
                <v-layout row justify-center pa-8>
                    <v-img
                        tile
                        :src="profile.profilePictureUrl"
                        max-height="18.75rem"
                        max-width="18.75rem"
                    ></v-img>
                    <v-list color="transparent" two-line>
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon
                                    v-text="'$vuetify.icons.email'"
                                ></v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    myProfile.email
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Email</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon
                                    v-text="'$vuetify.icons.phone'"
                                ></v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    phone
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Phone</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item
                            v-if="myProfile.permissionLevel !== 'User'"
                        >
                            <v-list-item-icon>
                                <v-icon
                                    v-text="'$vuetify.icons.userLock'"
                                ></v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    myProfile.permissionLevel
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Permission Level</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon
                                    v-text="'$vuetify.icons.clock'"
                                ></v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    myProfile.lastLogin
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Last Login</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>

                        <v-divider inset></v-divider>

                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon
                                    v-text="'$vuetify.icons.calendar'"
                                ></v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{
                                    myProfile.dateJoined
                                }}</v-list-item-title>
                                <v-list-item-subtitle
                                    >Date Joined</v-list-item-subtitle
                                >
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-layout>
            </v-container>
        </template>
    </v-flex>
</template>

<script>
export default {
    name: 'MyProfile',
    props: {
        profile: { type: Object, default() {} },
    },
    data() {
        return {
            myProfile: {
                firstName: null,
                lastName: null,
                email: null,
                permissionLevel: null,
                lastLogin: null,
                dateJoined: null,
                verified: null,
                profilePictureUrl: null,
                phoneNumber: null,
                countryCode: null,
            },
        };
    },
    computed: {
        phone() {
            if (this.myProfile.phoneNumber && this.myProfile.countryCode) {
                return `+${this.myProfile.countryCode} ${this.myProfile.phoneNumber}`;
            }
            return '';
        },
    },
    mounted() {
        Object.assign(this.myProfile, this.profile);
    },
    methods: {
        editClicked() {
            this.tabNavigated('edit');
        },
        passwordClicked() {
            this.tabNavigated('password');
        },
        notificationsClicked() {
            this.tabNavigated('notifications');
        },
        tabNavigated(tab) {
            this.$emit('tab-navigation', tab);
        },
    },
};
</script>

<style scoped></style>
