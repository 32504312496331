<template>
    <v-card class="mx-auto" max-width="344" outlined>
        <v-list-item three-line>
            <v-list-item-content>
                <div class="overline mb-12">
                    Credit Card
                </div>

                <v-list-item-subtitle class="mt-4">
                    <div style="font-size: 125%;">
                        **** **** **** {{ card.last4 }} <br />
                    </div>
                    <v-simple-table class="mt-2" dense fixed-header>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">Cardholder:</th>
                                    <th class="text-left">Expiry:</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{{ card.contact_name }}</td>
                                    <td>{{ card.expiry }}</td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-list-item-subtitle>
            </v-list-item-content>

            <!--            <v-list-item-avatar tile size="100">-->
            <!--                <v-img max-width="100" max-height="100" contain position="top" :src="require('./visa-logo.png')"/>-->
            <!--            </v-list-item-avatar>-->
        </v-list-item>
    </v-card>
</template>

<script>
export default {
    name: 'CreditCard',
    props: {
        card: {
            type: Object,
            default: function() {
                return null;
            },
        },
    },
};
</script>

<style scoped>
.theme--dark.v-data-table.v-data-table--fixed-header thead th {
    box-shadow: none;
}

.v-data-table td,
.v-data-table th {
    padding: 0;
}
</style>
