<template>
    <v-container color="py-0">
        <v-row style="margin: 0 auto; max-width: 1000px;">
            <v-spacer></v-spacer>
            <v-autocomplete
                id="email"
                ref="autocomplete"
                v-model="email"
                type="email"
                :loading="loading"
                :items="results"
                :search-input.sync="search"
                placeholder="Add user by email address"
                style="max-width: 400px;"
                autocomplete="off"
                ><template v-slot:no-data>
                    <v-list-item
                        v-if="!search || (search && search.length <= 3)"
                    >
                        <v-list-item-title>
                            <span v-text="search ? 'Keep' : 'Start'"></span>
                            typing to search for an email address
                        </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-else>
                        <v-list-item-title>
                            No matching email addresses.
                        </v-list-item-title>
                    </v-list-item>
                </template>
            </v-autocomplete>

            <v-btn
                color="primary"
                class="ml-3 mt-3 mr-4"
                :disabled="!email"
                :loading="loadingButton"
                @click="addUser"
                ><v-icon
                    style="font-size: 12pt"
                    v-text="'$vuetify.icons.register'"
                ></v-icon>
            </v-btn>
        </v-row>
    </v-container>
</template>

<script>
import axios from 'axios';
import Vue from 'vue';
import VuetifyConfirm from 'vuetify-confirm';

Vue.use(VuetifyConfirm);

export default {
    name: 'AddTeamMember',
    props: {
        addId: {
            default: null,
            type: String,
        },
    },
    data() {
        return {
            email: '',
            results: [],
            loading: false,
            currentResult: -1,
            modalMessage: '',
            search: '',
            inviteDialog: false,
            loadingButton: false,
        };
    },
    watch: {
        search(value) {
            if (!value) return;
            this.getResults(value);
        },
    },
    methods: {
        addUser() {
            this.loadingButton = true;
            axios
                .post(`/api/billing_account/${this.addId}/add/`, {
                    email: this.email,
                })
                .then(response => response.data)
                .then(data => {
                    this.loadingButton = false;

                    if (data.success) {
                        this.$emit('userAdded');
                        this.email = '';
                    } else {
                        this.$emit('snackbar', data.message);
                    }
                })
                .catch(() => {
                    this.$emit(
                        'snackbar',
                        'This user could not be added due to a server error.'
                    );
                });
        },
        getResults(value) {
            this.loading = true;
            // eslint-disable-next-line promise/catch-or-return
            axios
                .get(
                    `/api/billing_account/${this.addId}/get_emails?email=${value}`
                )
                .then(response => response.data)
                .then(data => {
                    this.loading = false;
                    data = data.results;

                    // add an already existing flag if the user is already on the team
                    this.results = data.map(r => r.email);

                    this.results = this.results.filter(item =>
                        item.toLowerCase().includes(this.search.toLowerCase())
                    );
                });
        },
        snackMessage(value) {
            this.$emit('snackbar', value);
        },
        userAdded() {
            this.$emit('userAdded');
        },
    },
};
</script>

<style></style>
