<template>
    <span>
        <div class="top-bar-bg">
            <div class="top-bar-container">
                <div class="headline">Support</div>
                <v-tabs v-model="currentTab" background-color="transparent">
                    <v-tab key="support">
                        General Support
                    </v-tab>
                    <v-tab key="compatibility">
                        Compatibility Check
                    </v-tab>
                    <v-tab key="user-guide">
                        User Guide
                    </v-tab>
                    <v-tab key="terms">
                        Terms of Use
                    </v-tab>
                </v-tabs>
            </div>
            <v-divider></v-divider>
        </div>

        <v-tabs-items
            v-model="currentTab"
            class="full-width pa-5"
            style="background-color: transparent;"
        >
            <v-tab-item key="support">
                <v-card flat class="tab-card" color="transparent">
                    <v-card-text>
                        <p class="body-1">
                            We can only offer support for questions about using
                            Smartdata or technical problems. For support
                            regarding dataset quality, please contact the
                            supplier that you ordered work from.
                        </p>
                        <p class="body-1">
                            Before asking for support we recommend that you
                            check the user guide and your browsers compatibility
                            with Smartdata by clicking the buttons in the menu
                            above.
                        </p>
                        <p class="body-1">
                            If you are having technical difficulties with using
                            Smartdata, or have any feedback you can:
                        </p>
                        <ol class="body-1">
                            <li>
                                Send an email to:
                                <a href="mailto:smartdata@nationaldrones.com.au"
                                    >smartdata@nationaldrones.com.au</a
                                >
                            </li>
                            <li>
                                Click the "help" button at the top of every page
                                (or in the menu) to get in contact with us.
                            </li>
                        </ol>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="compatibility">
                <v-card flat class="tab-card" color="transparent">
                    <v-card-text>
                        <v-alert v-if="compatible" type="success">
                            Your browser is compatible.
                        </v-alert>

                        <v-alert v-if="!compatible" type="error">
                            Your browser is not compatible.
                        </v-alert>

                        <v-alert v-if="compatInfo" type="info">
                            {{ compatInfo }}
                        </v-alert>

                        <p class="body-1">
                            This compatibility test only verifies the basic
                            software requirements and does not guarantee your
                            device will perform well with Smartdata™. For some
                            features to work correctly your device must also
                            meet these requirements, which this tool does not
                            check:
                        </p>
                        <ul class="body-1">
                            <li>
                                20mbps+ broadband internet connection
                            </li>
                            <li>Dual Core CPU (2.5GHz or faster)</li>
                            <li>Dedicated GPU</li>
                            <li>8GB RAM</li>
                        </ul>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="user-guide" class="fill-height">
                <v-card flat class="tab-card text-center" color="transparent">
                    <v-card-text>
                        <div class="headline">
                            Click the button below to access the user guide.
                        </div>
                        <br />
                        <v-btn
                            href="https://help.ndsmartdata.com/en/"
                            class="mx-auto"
                            target="_blank"
                            color="primary"
                        >
                            User guide
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-tab-item>

            <v-tab-item key="terms" class="fill-height">
                <v-card flat class="tab-card text-center" color="transparent">
                    <v-card-text>
                        <div class="headline">
                            Click the button below to access the terms of use.
                        </div>
                        <br />
                        <v-btn
                            href="https://nationaldrones-my.sharepoint.com/:b:/p/j_mayer/EXr6cm9BS1dLsvn2Zu6toAABFHSVyMlsm6O3taRqSafaZg?e=jWFYxn"
                            class="mx-auto"
                            target="_blank"
                            color="primary"
                        >
                            Terms of Use
                        </v-btn>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </span>
</template>

<script>
export default {
    name: 'SupportPage',
    props: {
        dark: {
            type: Boolean,
            default: false,
        },
        supportedBrowser: {
            type: Boolean,
            default: false,
        },
    },
    data: function() {
        return {
            currentTab: null,
            compatible: null,
            compatInfo: '',
        };
    },
    computed: {},
    watch: {},
    mounted() {
        // This function is called when the vue instance has loaded
        if (this.detectWebGL() && this.supportedBrowser) {
            this.compatible = true;
        } else {
            this.compatible = false;
            this.compatInfo =
                'Some features may not work correctly in your browser. We recommend using the latest version of Google Chrome for the best experience.';
        }
    },
    methods: {
        detectWebGL: function() {
            // Check for the WebGL rendering context
            if (window.WebGLRenderingContext) {
                let canvas = document.createElement('canvas'),
                    names = [
                        'webgl',
                        'experimental-webgl',
                        'moz-webgl',
                        'webkit-3d',
                    ],
                    context = false;

                for (const i in names) {
                    try {
                        context = canvas.getContext(names[i]);
                        if (
                            context &&
                            typeof context.getParameter === 'function'
                        ) {
                            // WebGL is enabled.
                            return 1;
                        }
                    } catch (error) {
                        continue;
                    }
                }
                // WebGL is supported, but disabled.
                return 0;
            }
            // WebGL not supported.
            return -1;
        },
    },
};
</script>
