<template>
    <v-container>
        <v-layout
            class="max-width-500 mx-auto"
            row
            justify-center
            :class="{ 'pa-8': $vuetify.breakpoint.mdAndUp }"
        >
            <v-subheader
                v-if="$vuetify.breakpoint.smAndDown"
                class="my-2 full-width"
                @click="profileClicked"
            >
                <v-btn icon>
                    <v-icon v-text="'$vuetify.icons.prev'" />
                </v-btn>
                <div class="headline">Settings</div>
            </v-subheader>

            <v-list
                class="mx-3 full-width"
                color="transparent"
                subheader
                two-line
                flat
            >
                <div class="title">Notifications</div>
                <v-list-item>
                    <v-list-item-action>
                        <v-switch
                            v-model="settings.datasetNotifications"
                            @change="changeSettings"
                        />
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title
                            >Dataset Notifications</v-list-item-title
                        >
                        <v-list-item-subtitle>
                            Notify me about updates to dataset processing and
                            other related events.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-action>
                        <v-switch
                            v-model="settings.teamAndBillingNotifications"
                            @change="changeSettings"
                        />
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title
                            >Team and Billing Notifications</v-list-item-title
                        >
                        <v-list-item-subtitle>
                            Notify me about updates to teams and billing account
                            related events.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-list-item>
                    <v-list-item-action>
                        <v-switch input-value="true" disabled />
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title
                            >Mandatory Notifications</v-list-item-title
                        >
                        <v-list-item-subtitle>
                            You will always receive important account related
                            notifications such as password resets or billing
                            issues.
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <div class="title">Security</div>
                <v-list-item>
                    <v-list-item-action>
                        <v-switch
                            v-model="settings.twoFactorAuthentication"
                            :disabled="!profile.authyId"
                            @change="changeSettings"
                        />
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title
                            >Two Factor Authentication</v-list-item-title
                        >
                        <v-list-item-subtitle>
                            Protect your account with two factor authentication.
                            If not enabled your security is significantly
                            reduced.
                            <template v-if="!profile.authyId"
                                >To enable this option please register and
                                confirm your phone number in Edit
                                Profile</template
                            >
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <div class="title mt-2">Localisation</div>

                <v-list-item>
                    <v-autocomplete
                        v-model="settings.timezone"
                        label="Time Zone"
                        required
                        value="profile.timezone"
                        :items="timezoneArray"
                        prepend-icon="$vuetify.icons.clock"
                        autocomplete="off"
                        @change="changeSettings"
                    ></v-autocomplete>
                </v-list-item>
            </v-list>
            <generic-snack-bar ref="snackbar" />
        </v-layout>
    </v-container>
</template>

<script>
import axios from 'axios';
import GenericSnackBar from 'components/GenericSnackbar/GenericSnackBar.vue';
import { trackProfileEvents } from 'js/eventsTracking';

export default {
    name: 'ManageNotifications',
    components: { GenericSnackBar },
    props: {
        userSettings: {
            type: Object,
            default: () => {
                return {
                    datasetNotifications: null,
                    teamAndBillingNotifications: null,
                    twoFactorAuthentication: null,
                };
            },
        },
        profile: {
            type: Object,
            default: () => {
                return {};
            },
        },
        timezoneArray: {
            type: Array,
            default: () => {
                return [];
            },
        },
    },
    data() {
        return {
            settings: {
                datasetNotifications: false,
                teamAndBillingNotifications: false,
                twoFactorAuthentication: false,
                timezone: null,
            },
        };
    },
    created() {
        if (this.userSettings) {
            Object.assign(this.settings, this.userSettings);
        } else {
            this.getUserSettings();
        }
    },
    methods: {
        changeSettings() {
            axios
                .post(`/api/accounts/settings/`, {
                    settings: this.settings,
                })
                .then(response => {
                    if (response.status === 200) {
                        trackProfileEvents.changedNotificationSettings(
                            response.data.datasetNotifications,
                            response.data.teamAndBillingNotifications
                        );

                        Object.assign(this.settings, response.data.settings);

                        this.$refs.snackbar.show(
                            'Your changes have been saved.'
                        );
                    } else {
                        this.$refs.snackbar.show(
                            "Your changes couldn't be saved. Please try again."
                        );
                    }
                })
                .catch(error => {
                    this.$refs.snackbar.show(
                        "Your changes couldn't be saved. Please try again."
                    );
                    throw error;
                });
        },
        getUserSettings() {
            axios
                .get(`/api/accounts/settings/`)
                .then(response => response.data)
                .then(data => {
                    Object.assign(this.settings, data.settings);
                })
                .catch(() =>
                    this.$refs.snackbar.show(
                        'There was an error updating your settings.'
                    )
                );
        },
        profileClicked() {
            this.$emit('tab-navigation', 'profile');
        },
    },
};
</script>

<style scoped />
