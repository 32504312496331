import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"tab-card",attrs:{"flat":"","color":"transparent"}},[_c(VCardTitle,[_c('add-billing-account-administrator',{attrs:{"add-id":_vm.billingAccountId,"title":'Current Administrators'},on:{"userAdded":_vm.updateAdmins,"snackbar":_vm.showSnackbar}})],1),_vm._v(" "),_c(VContainer,{attrs:{"grid-list-md":"","text-left":""}},[(_vm.loading)?_c(VProgressLinear,{attrs:{"indeterminate":"","color":"primary"}}):_vm._e(),_vm._v(" "),_c(VLayout,{attrs:{"wrap":""}},_vm._l((_vm.admins),function(admin,index){return _c(VFlex,{key:index,attrs:{"pa-2":"","xs12":"","sm12":"","md6":""}},[_c(VCard,[_c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(admin.name))]),_vm._v(" "),_c(VListItemSubtitle,[_vm._v(_vm._s(admin.email))])],1),_vm._v(" "),_c(VListItemAvatar,[_c(VImg,{attrs:{"src":admin.profilePictureUrl}})],1)],1),_vm._v(" "),_c(VCardActions,[_c(VBtn,{attrs:{"disabled":admin.isCurrentUser,"color":"primary","text":""},on:{"click":function($event){return _vm.removeAdmin(
                                    admin.name,
                                    admin.removeAdminUrl
                                )}}},[_vm._v("Remove\n                        ")])],1)],1)],1)}),1)],1),_vm._v(" "),_c('generic-snack-bar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }