import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VFlex,[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{class:_vm.dark ? 'top-bar-bg-dark' : 'top-bar-bg'},[_c('div',{staticClass:"top-bar-container"},[_c('div',{staticClass:"headline"},[_vm._v("Request Password Reset")])]),_vm._v(" "),_c(VDivider)],1):_vm._e(),_vm._v(" "),_c(VCard,{staticClass:"tab-card",attrs:{"flat":"","color":"transparent","justify-center":""}},[_c(VContainer,[_c(VList,{attrs:{"justify-center":"","color":"transparent"}},[(_vm.response)?_c(VListItem,[_c(VAlert,{attrs:{"dense":"","outlined":"","type":_vm.requestSuccessful ? 'success' : 'error',"text":""}},[_vm._v("\n                        "+_vm._s(_vm.message)+"\n                    ")])],1):_vm._e(),_vm._v(" "),(!_vm.response)?_c(VListItem,[_c('p',[_vm._v("\n                        Enter your email and a password reset link will be\n                        sent to your email address.\n                    ")])]):_vm._e()],1),_vm._v(" "),(!_vm.response || !_vm.requestSuccessful)?_c(VLayout,{class:{ 'pa-8': _vm.$vuetify.breakpoint.mdAndUp },attrs:{"row":"","justify-center":""}},[_c(VForm,{staticClass:"full-width"},[_c(VContainer,[_c(VList,{attrs:{"color":"transparent","subheader":"","two-line":"","flat":""}},[_c(VListItem,[_c(VTextField,{staticClass:"pb-4",attrs:{"label":"Email","required":"","outlined":"","prepend-icon":"$vuetify.icons.email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1),_vm._v(" "),_c(VLayout,{staticClass:"mt-5",attrs:{"justify-center":""}},[_c(VBtn,{attrs:{"large":"","color":"primary"},on:{"click":_vm.requestPasswordReset}},[_vm._v("Request Password Reset"),_c(VIcon,{attrs:{"right":""},domProps:{"textContent":_vm._s('$vuetify.icons.next')}})],1)],1)],1)],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }